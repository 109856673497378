import React, { useContext, useEffect, useState } from "react";
import UseAuthFirebase from "../Auth/UseAuthFirebase";
import Login from "../Components/Login";
import { dataUserContext } from "../Context/UserContext";
import UseFireBase from "../Hooks/UseFireBase";

function RoutePrivate(props) {
  const { authState } = UseAuthFirebase();
  const { getDataWhere } = UseFireBase();
  const [isAuth, setIsAuth] = useState(false);
  const { setDataUser, dataUser } = useContext(dataUserContext)

  const getAuthStatus = () => {
    authState()
      .then((uid) => {
        getDataWhere("users", "id_user", uid).then((data) => {
          setDataUser(data)
          setIsAuth("login");
        });
      })
      .catch(() => setIsAuth("logout"));
  };
  
  useEffect(() => {
    getAuthStatus();
  }, []);

  return !isAuth && !dataUser ? (
    "Cargando..."
  ) : isAuth === "login" ? (
    <props.component />
  ) : (
    <Login />
  );
}

export default RoutePrivate;
