import React, { useEffect, useState } from "react";
import UseFireBase from "../../Hooks/UseFireBase";
import ListAdviser from "./ListAdviser";
import NewAdviser from "./NewAdviser";
import "./Style.css";

export default function Adviser() {
  const { getData } = UseFireBase();
  const [selected, setSelected] = useState("ListAdviser");
  const [data, setData] = useState(false);

  const getDataAdvisors = async () => {
    const dataAdvisor = await getData("users");
    setData(dataAdvisor);
  };
  useEffect(() => {
    getDataAdvisors();
  }, []);

  return (
    <div className="principal">
      {selected === "ListAdviser" && (
        <ListAdviser setSelected={setSelected} data={data} />
      )}
      {selected === "NewAdviser" && (
        <NewAdviser setSelected={setSelected} setData={setData} />
      )}
    </div>
  );
}
