import React, { useContext } from "react";
import { Link } from "react-router-dom";
import LateralBar from "../Components/LateralBar";
import { dataUserContext } from "../Context/UserContext";
import "./Style.css";

export default function Dashboard() {
  const { dataUser } = useContext(dataUserContext);
  return (
    <main className="dashboard">
      <LateralBar />
      <section className="content-dashboard">
        <h1>Dashboard</h1>
        <div className="grid-dashboard">
          <Link to={"/advisor"}>
            <i className="fa-solid fa-users"></i>
            Usuarios
          </Link>

          <Link to={"deliveries"}>
            <i className="fa-solid fa-truck"></i>
            Entregas
          </Link>

          <Link to={"user"}>
            <i className="fa-solid fa-user-gear"></i>
            Configuracion de usuario
          </Link>
        </div>
      </section>
    </main>
  );
}
