import React from 'react'
import LateralBar from '../Components/LateralBar';
import Product from '../Components/Product';

function ViewProduct() {
    return (
        <main className="dashboard">
          <LateralBar select={"product"}/>
          <section className="content-dashboard">
            <Product />
          </section>
        </main>
      );
}

export default ViewProduct