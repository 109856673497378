import React, { createContext } from "react";
import { useState } from "react";

export const dataUserContext = createContext();

function UserContext({ children }) {
  const [dataUser, setDataUser] = useState(false);
  return (
    <dataUserContext.Provider value={{ dataUser, setDataUser }}>
      {children}
    </dataUserContext.Provider>
  );
}

export default UserContext;
