import React, { useEffect, useRef } from "react";
import NewDeliveries from "../Components/Deliveries/NewDeliveries";
import LateralBarUser from "../Components/LateralBar/LateralBarUser";

function ViewDeliveryUser() {
  
  return (
    <main className="main-new-delivery-user">
      <LateralBarUser />
      <section className="dashboard-advisor">
        <NewDeliveries />
      </section>
    </main>
  );
}

export default ViewDeliveryUser;
