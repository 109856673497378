import React, { useEffect, useState } from "react";
import UseFireBase from "../../Hooks/UseFireBase";
import ListZones from "./ListZones.js";
import NewZones from "./NewZone";

function Zones() {
  const { getData } = UseFireBase();
  const [selected, setSelected] = useState("ListZones");
  const [data, setData] = useState(false);

  const getDataZones = async () => {
    const dataZones = await getData("zones");
    setData(dataZones);
  };

  useEffect(() => {
    getDataZones();
  }, []);

  return (
    <div className="principal">
      {selected === "ListZones" && (
        <ListZones setSelected={setSelected} data={data} />
      )}
      {selected === "NewZone" && (
        <NewZones setSelected={setSelected} setData={setData} getDataZones={getDataZones} />
      )}
    </div>
  );
}

export default Zones;
