import React from 'react'
import LateralBar from '../Components/LateralBar';
import PersonalInformation from "../Components/PersonalInformation";

function ViewsUser() {
  return (
    <main className="dashboard">
      <LateralBar select={"user"}/>
      <section className="content-dashboard">
        <PersonalInformation />
      </section>
    </main>
  );
}

export default ViewsUser