import { PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import UseFireBase from "../../Hooks/UseFireBase";
import DeliveryDetail from "./DeliveryDetail";
import ListDeliveries from "./ListDeliveries";
import "./Style.css";

export default function Deliveries({ select }) {
  const { getData } = UseFireBase();
  const [data, setData] = useState(false);
  const [deliveries, setDeliveries] = useState(false);
  const [selected, setSelected] = useState("ListDelivery");
  const [delivery, setDelivery] = useState(false);

  const getDataDeliveries = async () => {
    const dataDeliveries = await getData("deliveries");
    const dataProducts = await getData("products");
    const dataZones = await getData("zones");
    const dataUsers = await getData("users");

    setData({ dataDeliveries, dataProducts, dataUsers, dataZones });
  };

  useEffect(() => {
    getDataDeliveries();
  }, []);

  useEffect(() => {
    if (data) {
      const join = data.dataDeliveries.map((delivery) => {
        const u = data.dataUsers.find(
          (user) => user.id === delivery.data.id_user
        );
        const z = data.dataZones.find((zone) => zone.id === delivery.data.zone);
        const p = delivery.data.ids_products.map((id_product) =>
          data.dataProducts.find((product) => product.id === id_product)
        );

        const productsName = p.map((producto) => producto.data.name);

        return {
          user: u,
          products: productsName,
          id: delivery.id,
          delivery: delivery.data,
          zone: z !== undefined ? z : "N/A",
        };
      });
      setDeliveries(
        join.sort(
          (a, b) => new Date(a.delivery.date) - new Date(b.delivery.date)
        )
      );
    }
  }, [data]);

  return (
    <div className="principal">
      {selected === "ListDelivery" && (
        <ListDeliveries
          data={deliveries}
          getDataDeliveries={getDataDeliveries}
          setDelivery={setDelivery}
          setSelected={setSelected}
        />
      )}
      {selected === "DeliveryDetail" && (
        <div>
          <button
            className="buttom-to"
            onClick={() => setSelected("ListDelivery")}
          >
            <i className="fa-solid fa-chevron-left"></i>Regresar
          </button>
          <PDFViewer style={{ width: "100%", height: "90vh" }}>
            <DeliveryDetail delivery={delivery} />
          </PDFViewer>
        </div>
      )}
    </div>
  );
}
