import React from "react";
import LateralBar from "../Components/LateralBar";
import Zones from "../Components/Zones";

function ViewZones() {
  return (
    <main className="dashboard">
      <LateralBar select={"product"} />
      <section className="content-dashboard">
        <Zones />
      </section>
    </main>
  );
}

export default ViewZones;
