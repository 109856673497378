import React from "react";
import "./style.css";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

function DeliveryDetail({ delivery}) {

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View>
            <Text
              style={{ fontSize: "24px", width: "100%", textAlign: "center" }}
            >
              {" "}
              {delivery.delivery.name_wirehouse}
            </Text>
            <Text
              style={{ fontSize: "14px", width: "100%", textAlign: "center" }}
            >
              {delivery.delivery.direction_wirehouse}
            </Text>
          </View>

          <Text style={{ fontSize: "12px", marginTop: "20px" }}>
            Nombre mostrador: {delivery.delivery.name_counter}
          </Text>
          <Text style={{ fontSize: "12px", marginTop: "7px" }}>
            Teléfono del mostrador: {delivery.delivery.phone_counter}
          </Text>
          <Text style={{ fontSize: "12px", marginTop: "7px" }}>
            Asesor LEGRAND: {delivery.user.data.name}{" "}
            {delivery.user.data.lastname}
          </Text>
          <Text style={{ fontSize: "12px", marginTop: "7px" }}>
            Fecha y hora de entrega: {delivery.delivery.date}
          </Text>
          <Text style={{ fontSize: "12px", marginTop: "7px" }}>
            Productos entregados: {delivery.products.map((p) => p + ", ")}
          </Text>
          <Text
            style={{
              marginTop: "7px",
            }}
          >
            Imagenes (antes)
          </Text>
          <View
            style={{
              marginTop: "7px",
              fontSize: "24px",
              paddingTop: "5px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {delivery.delivery.images_before.map(
              (image, i) =>
                i < 3 && (
                  <Image
                    key={i}
                    src={image}
                    style={{ width: "30%", marginRight: "1%" }}
                  ></Image>
                )
            )}
          </View>
          <Text
            style={{
              marginTop: "25px",
            }}
          >
            Imagenes (despúes)
          </Text>
          <View
            style={{
              marginTop: "7px",
              fontSize: "24px",
              paddingTop: "5px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {delivery.delivery.images_After.map(
              (image, i) =>
                i < 3 && (
                  <Image
                    key={i}
                    src={image}
                    style={{ width: "30%", marginRight: "1%" }}
                  ></Image>
                )
            )}
          </View>
          <Text
            style={{
              marginTop: "25px",
            }}
          >
            Observaciones
          </Text>
          <Text
            style={{
              marginTop: "25px",
            }}
          >
            {delivery.delivery.observations}
          </Text>
        </View>
      </Page>
    </Document>
  );
}

export default DeliveryDetail;
