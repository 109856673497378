import React from 'react'
import "./Style.css"

export default function PersonalInformation() {
  return (
    <div className="principal">
        <h1>Información Personal</h1>
        <div className='info-user'>
            <div className='img-user'>
                <div className='img'>
                   <img src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"></img>
                </div>
                <div className='new-img'>
                    <i className="fa-sharp fa-solid fa-pen-to-square"></i>
                    Nueva imagen
                </div>
            </div>
            <div className='data-user'>
                <form>
                    <label>Nombres</label>
                    <input type="text" name="name" placeholder='John Alex' required></input>
                    <label>Apellidos</label>
                    <input type="text" name="lastname" placeholder='Doe Doe' required></input>
                    <button>Guardar Cambios</button>
                </form>  
            </div>
        </div>
        <h1>Información de Seguridad</h1>
        <div className='info-sec'>
            <form>
                <div className='sec-num'>
                    <label>Teléfono</label>
                    <input type="text" name="name"></input>
                    <i className="fa-sharp fa-solid fa-pen-to-square"></i>
                </div>
                <div className='sec-email'>
                    <label>Email</label>
                    <input type="text" name="name"></input>
                    <i className="fa-sharp fa-solid fa-pen-to-square"></i>
                </div>
                <div className='sec-pass'>
                    <label>Contraseña</label>
                    <input type="password" name="name"></input>
                    <i className="fa-sharp fa-solid fa-pen-to-square"></i>
                </div>
            </form>
        </div>
    </div>
  )
}
