import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { dataUserContext } from "../../../Context/UserContext";
import UseFireBase from "../../../Hooks/UseFireBase";
import "./Style.css";

export default function ListDeliveries({
  setSelected,
  data,
  getDataDeliveries,
  setDelivery,
}) {
  const { dataUser } = useContext(dataUserContext);
  const { deleteDocWithId } = UseFireBase();
  const [deliveries, setDeliveries] = useState([]);
  const [search, setSearch] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const [csvReport, setCsvReport] = useState(false);
  const [filterDate, setFilterDate] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [endOffset, setEndOffset] = useState(10);
  const [dataFilter, setDataFilter] = useState(false);
  const headers = [
    { label: "Asesor", key: "user.data.name" },
    { label: "Productos", key: "products" },
    { label: "Almacén", key: "delivery.name_wirehouse" },
    { label: "Fecha", key: "zone.data.name" },
    { label: "Direccion almacén", key: "delivery.direction_wirehouse" },
    { label: "Mostrador", key: "delivery.name_counter" },
    { label: "Teléfono de Mostrador", key: "delivery.phone_counter" },
    { label: "Fecha", key: "delivery.date" },
  ];

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % data.length;
    setItemOffset(newOffset);
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleChangeSearchForDate = (e) => {
    setFilterDate(e.target.value);
  };

  const filterDeliveries = () => {
    const dataDeliveries = [...data];
    const filter = dataDeliveries.filter(
      (delivery) =>
        delivery.user.data.name.toUpperCase().includes(search.toUpperCase()) ||
        delivery.delivery.name_wirehouse
          .toUpperCase()
          .includes(search.toUpperCase()) ||
        delivery.products.find((producto) =>
          producto.toLowerCase().includes(search.toLowerCase())
        )
    );
    setPageCount(Math.ceil(filter.length / 10));
    setDataFilter(filter);
    setDeliveries(filter.slice(itemOffset, itemOffset + 10));
  };

  const filterDeliveriesForDate = (date) => {
    const dataDeliveries = [...data];
    const filter = dataDeliveries.filter(
      (delivery) =>
        new Date(delivery.delivery.date).toLocaleDateString("en-us") ===
        new Date(
          new Date(date).setDate(new Date(date).getDate() + 1)
        ).toLocaleDateString("en-us")
    );
    setPageCount(Math.ceil(filter.length / 10));
    setDataFilter(filter);
    setDeliveries(filter.slice(itemOffset, itemOffset + 10));
  };

  function deleteData(data) {
    Swal.fire({
      title: "Deseas Eliminar la entrega",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteDocWithId("deliveries", data.id).then(() => {
          Swal.fire("Se elimino con exito", "", "success").then(() => {
            getDataDeliveries();
          });
        });
      } else if (result.isDenied) {
        Swal.fire("Hubo un Error", "", "info");
      }
    });
  }

  function viewComment(comment) {
    Swal.fire({
      title: "Comentarios",
      showDenyButton: false,
      showCancelButton: false,
      html: `${
        comment === "" || comment === undefined ? "No hay comentarios" : comment
      }`,
    });
  }

  useEffect(() => {
    setEndOffset(itemOffset + 10);

    if (filterDate) {
      filterDeliveriesForDate(filterDate);
    } else if (search !== "") {
      filterDeliveries();
    } else if (deliveries.length > 0) {
      setDeliveries(data && data.slice(itemOffset, itemOffset + 10));
    }
  }, [itemOffset]);

  useEffect(() => {
    if (search !== "") filterDeliveries();
    if (deliveries.length > 0 && search === "")
      setDeliveries(data.slice(itemOffset, endOffset));
  }, [search]);

  useEffect(() => {
    if (filterDate) {
      filterDeliveriesForDate(filterDate);
    }
  }, [filterDate]);

  useEffect(() => {
    if (data) setDeliveries(data.slice(itemOffset, endOffset));
    if (data && !csvReport) {
      setCsvReport({
        data: data,
        headers: headers,
        filename: "Reporte_" + new Date().toLocaleDateString("en-us") + ".csv",
      });
      setPageCount(Math.ceil(data.length / 10));
    }
  }, [data]);

  return (
    <div className="principal">
      <div className="filter">
        <div className="filter-box">
          <i className="fa-sharp fa-solid fa-filter"></i>
          <input
            type="text"
            name="deliveries"
            onChange={handleChangeSearch}
            placeholder={"Buscar..."}
            value={search}
          ></input>
          {search !== "" && (
            <i
              className="fa-regular fa-circle-xmark remove-all-filter"
              onClick={() => setSearch("")}
            />
          )}
          <input
            type="date"
            name="deliveries"
            onChange={handleChangeSearchForDate}
          ></input>
        </div>
        {dataFilter && (
          <CSVLink
            data={dataFilter}
            headers={headers}
            filename={
              "Reporte_" + new Date().toLocaleDateString("en-us") + ".csv"
            }
            className="buttom-download mrg-right"
          >
            <button className="boton-to-excel">Descargar filtro</button>
          </CSVLink>
        )}
        {csvReport && (
          <CSVLink {...csvReport} className="buttom-download">
            <button className="boton-to-excel">Descargar todo</button>
          </CSVLink>
        )}
      </div>
      {deliveries.length > 0 ? (
        <div className="table">
          <table className="table-responsive">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Asesor</th>
                <th scope="col">Productos</th>
                <th scope="col">Almacén</th>
                <th scope="col">Zona</th>
                <th scope="col">Mostrador</th>
                <th scope="col">Teléfono</th>
                <th scope="col">Dirección</th>
                <th scope="col">Fecha</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {deliveries &&
                deliveries.map((deliveries, i) => {
                  const date =
                    new Date(deliveries.delivery.date).toLocaleDateString(
                      "en-us"
                    ) +
                    " " +
                    new Date(deliveries.delivery.date).toLocaleTimeString();
                  try {
                    return (
                      <tr key={i}>
                        <td>{i + itemOffset + 1}</td>
                        <td>
                          {deliveries.user !== undefined &&
                            deliveries.user.data.name}
                        </td>
                        <td>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                          >
                            {deliveries.products.map((product, i) => (
                              <option key={i}>{product}</option>
                            ))}
                          </select>
                        </td>
                        <td>{deliveries.delivery.name_wirehouse}</td>
                        <td>
                          {deliveries.zone !== "N/A"
                            ? deliveries.zone.data.name.toUpperCase()
                            : "N/A"}
                        </td>
                        <td>{deliveries.delivery.name_counter}</td>
                        <td>{deliveries.delivery.phone_counter}</td>
                        <td>{deliveries.delivery.direction_wirehouse}</td>
                        <td>{date}</td>
                        <td>
                          <div className="button-actions">
                            <i className="fa-sharp fa-solid fa-pen-to-square"></i>
                            <i
                              className="fa-solid fa-comment-dots"
                              onClick={() => {
                                viewComment(deliveries.delivery.observations);
                              }}
                            ></i>
                            <i
                              className="fa-solid fa-people-carry-box"
                              onClick={() => {
                                setDelivery(deliveries);
                                setSelected("DeliveryDetail");
                              }}
                            ></i>

                            <i
                              className="fa-solid fa-trash"
                              onClick={() => deleteData(deliveries)}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    );
                  } catch (error) {
                    console.error(error);
                  }
                })}
            </tbody>
          </table>
          <ReactPaginate
            className="paginate"
            activeLinkClassName="paginate-active"
            activeClassName="paginate-active"
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
          />
        </div>
      ) : (
        "Cargando..."
      )}
    </div>
  );
}
