import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Style.css";

export default function ListAdviser({ setSelected, data }) {
  const [advisors, setAdvisors] = useState(false);
  const [search, setSearch] = useState("");

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const filterAvisors = () => {
    const dataAdvisor = [...data];
    const filter = dataAdvisor.filter(
      (advisor) =>
        advisor.data.name.toUpperCase().includes(search.toUpperCase()) ||
        advisor.data.lastname.toUpperCase().includes(search.toUpperCase()) ||
        advisor.data.email.toUpperCase().includes(search.toUpperCase())
    );
    setAdvisors(filter);
  };

  useEffect(() => {
    if (search !== "") filterAvisors();
    if (advisors && search === "") setAdvisors(data);
  }, [search]);

  useEffect(() => {
    setAdvisors(data);
  }, [data]);

  return (
    <div className="principal">
      <div className="fetch">
        <div className="find">
          <i className="fa-sharp fa-solid fa-magnifying-glass"></i>
          <input
            className="form-control col-md-3 light-table-filter"
            data-table="order-table"
            type="text"
            placeholder="Search.."
            onChange={handleChangeSearch}
          ></input>
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setSelected("NewAdviser")}
        >
          Agregar Asesor{" "}
        </button>
      </div>
      {advisors ? (
        <div className="table">
          <table className="table table-bordered order-table">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Nombres</th>
                <th scope="col">Apellidos</th>
                <th scope="col">Correo</th>
                <th scope="col">Teléfono</th>
                <th>...</th>
              </tr>
            </thead>
            <tbody>
              {advisors &&
                advisors.map((advisor, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{advisor.data.name}</td>
                    <td>{advisor.data.lastname}</td>
                    <td>{advisor.data.email}</td>
                    <td>{advisor.data.phone}</td>
                    <td>
                      <Link to={`/delivery/user/${advisor.id}`}>
                        <i className="fa-solid fa-link"></i>
                      </Link>
                      <i className="fa-sharp fa-solid fa-pen-to-square"></i>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        "Cargando..."
      )}
    </div>
  );
}
