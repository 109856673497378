import React from "react";
import { useEffect } from "react";
import { useState } from "react";

function ListZones({ setSelected, data }) {
  const [zones, setZones] = useState(false);
  const [search, setSearch] = useState("");

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const filterZones = () => {
    const dataZones = [...data];
    const filter = dataZones.filter((zone) =>
      zone.data.name.toUpperCase().includes(search.toUpperCase())
    );
    setZones(filter);
  };

  useEffect(() => {
    if (search !== "") filterZones();
    if (zones && search === "") setZones(data);
  }, [search]);

  useEffect(() => {
    setZones(data);
  }, [data]);

  return (
    <div className="principal">
      <div className="fetch">
        <div className="find">
          <i className="fa-sharp fa-solid fa-magnifying-glass"></i>
          <input
            className="form-control col-md-3 light-table-filter"
            data-table="order-table"
            type="text"
            placeholder="Search.."
            onChange={handleChangeSearch}
          ></input>
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setSelected("NewZone")}
        >
          Agregar Zona
        </button>
      </div>
      {zones ? (
        <div className="table">
          <table className="table table-bordered order-table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nombres</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {zones &&
                zones.map((zone, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{zone.data.name.toUpperCase()}</td>
                    <td>
                      <i className="fa-sharp fa-solid fa-pen-to-square"></i>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        "Cargando..."
      )}
    </div>
  );
}

export default ListZones;
