import React from "react";
import Adviser from "../Components/Adviser";
import LateralBar from "../Components/LateralBar";

function ViewAdvisor() {
  return (
    <main className="dashboard">
      <LateralBar select={"advisor"}/>
      <section className="content-dashboard">
        <Adviser />
      </section>
    </main>
  );
}

export default ViewAdvisor;
