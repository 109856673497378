import React from 'react'
import Deliveries from '../Components/Deliveries';
import LateralBar from '../Components/LateralBar';

function ViewDelivery() {
  return (
    <main className="dashboard">
      <LateralBar select={"deliveries"}/>
      <section className="content-dashboard">
        <Deliveries />
      </section>
    </main>
  );
}

export default ViewDelivery