import axios from "axios";
import React, { useRef, useState } from "react";
import Swal from "sweetalert2";
import ReactDOM from "react-dom";
import PopupInputImages from "./PopupInputImages";

function AddImages({ images, setImages, open }) {
  const [scrollAction, setScrollAction] = useState({ prev: 100, next: 100 });
  const viewImages = useRef();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [alert, setAlert] = useState(false);

  const handleClickImages = () => {
    Swal.fire({
      title: "Agrega una Imagen",
      html: `<div id="container-images"></div>`,
      didOpen: () =>
        ReactDOM.render(
          <PopupInputImages handleChange={handleChange} />,
          document.getElementById("container-images")
        ),
      willClose: () =>
        ReactDOM.unmountComponentAtNode(
          document.getElementById("container-images")
        ),
      showDenyButton: true,
      confirmButtonText: "Listo",
      confirmButtonColor: "#0d6efd",
      denyButtonText: "Cancelar",
      denyButtonColor: "gray",
    });
  };

  const handleChange = (e) => {
    handleSubmit(e.target.files[0]);
  };

  const handleSubmit = (file) => {
    const formData = new FormData();
    formData.append("image", file);
    axios
      .post(
        "https://rg7-legrand-api.azurewebsites.net/api/v1/uploads",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            setUploadPercentage(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );
          },
        }
      )
      .then((res) => {
        setImages((prevImg) => [...prevImg, res.data.url]);
        setUploadPercentage(0);
      })
      .catch((err) => {
        console.log(err);
        setAlert("No se cargo la imagen correctamente");
        setUploadPercentage(0);
        setTimeout(() => {
          setAlert(false);
        }, 5000);
      });
  };

  const handleScroll = (type, e) => {
    e.preventDefault();
    if (type === "next") {
      viewImages.current.scrollLeft = scrollAction.next;
      setScrollAction({ next: scrollAction.next + 100, prev: 100 });
    } else {
      viewImages.current.scrollLeft = scrollAction.prev;
      setScrollAction({ next: 100, prev: scrollAction.prev - 100 });
    }
  };

  return (
    <div className="galery">
      {uploadPercentage !== 0 && (
        <progress
          max="100"
          value={uploadPercentage}
          style={{ width: "100%", marginBottom: "10px" }}
        ></progress>
      )}
      {alert && <p style={{ color: "red" }}>{alert}</p>}
      <button
        className="btn btn-primary"
        disabled={open}
        onClick={handleClickImages}
      >
        Agregar imagen
      </button>

      <div className="prev">
        <button
          className="btn btn-primary width100"
          onClick={(e) => handleScroll("prev", e)}
        >
          <i className="fa-solid fa-angle-left"></i>
        </button>
      </div>
      <div className="images-galery" ref={viewImages}>
        {images &&
          images.map((img, i) => (
            <div className="item-image-galery" key={i}>
              {img !== "temp" ? (
                <i
                  className="fa-solid fa-circle-xmark"
                  onClick={() =>
                    setImages((prevImg) =>
                      prevImg.filter((imgT) => imgT !== img)
                    )
                  }
                ></i>
              ) : (
                <i></i>
              )}
              <img
                src={
                  img !== "temp"
                    ? img
                    : "https://static.thenounproject.com/png/3322766-200.png"
                }
              />
            </div>
          ))}
      </div>

      <div className="next">
        <button
          className="btn btn-primary"
          onClick={(e) => handleScroll("next", e)}
        >
          <i className="fa-solid fa-angle-right"></i>
        </button>
      </div>
    </div>
  );
}

export default AddImages;
