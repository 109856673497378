import React, { useEffect, useState } from "react";

function ListProducts({ setSelected, data }) {
  const [products, setProducts] = useState(false);
  const [search, setSearch] = useState("");

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const filterProducts = () => {
    const dataProducts = [...data];
    const filter = dataProducts.filter(
      (product) =>
        product.data.name.toUpperCase().includes(search.toUpperCase()) 
    );
    setProducts(filter);
  };

  useEffect(() => {
    if (search !== "") filterProducts();
    if (products && search === "") setProducts(data);
  }, [search]);

  useEffect(() => {
    setProducts(data);
  }, [data]);

  return (
    <div className="principal">
      <div className="fetch">
        <div className="find">
          <i className="fa-sharp fa-solid fa-magnifying-glass"></i>
          <input
            className="form-control col-md-3 light-table-filter"
            data-table="order-table"
            type="text"
            placeholder="Search.."
            onChange={handleChangeSearch}
          ></input>
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setSelected("NewProduct")}
        >
          Agregar Producto
        </button>
      </div>
      {products ? (
        <div className="table">
          <table className="table table-bordered order-table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nombres</th>
                <th scope="col">Descripcion</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {products &&
                products.map((product, i) => (
                  <tr key={i}>
                    <td>{i}</td>
                    <td>{product.data.name}</td>
                    <td>{product.data.desc}</td>
                    <td>
                      <i className="fa-sharp fa-solid fa-pen-to-square"></i>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        "Cargando..."
      )}
    </div>
  );
}

export default ListProducts;
