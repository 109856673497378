import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import UseFireBase from "../../../Hooks/UseFireBase";

export default function NewAdviser({ setSelected }) {
  const { add } = UseFireBase();
  const [dataForm, setDataForm] = useState({});

  const inputOnChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
  };

  const formHandleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Confirmar creación",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const newUser = {
          email: dataForm.email,
          lastname: dataForm.lastname,
          name: dataForm.name,
          phone: dataForm.phone,
        };
        add("users", newUser)
          .then(() => {
            Swal.fire("Se guardo con exito", "", "success").then(() => {
              e.target.reset();
              setSelected("ListAdviser");
            });
          })
          .catch(() => Swal.fire("Hubo un error al registrar", "", "error"));
      } else if (result.isDenied) {
        Swal.fire("Se Cancelo", "", "info");
      }
    });
  };

  return (
    <div className="principal">
      <div className="bt1">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setSelected("ListAdviser")}
        >
          <i className="fa-solid fa-arrow-left"></i>
        </button>
      </div>
      <h1>Información Asesor - LEGRAND</h1>
      <div className="info-user">
        <div className="data-user">
          <form onSubmit={formHandleSubmit}>
            <label>Nombres</label>
            <input
              type="text"
              name="name"
              placeholder="Escribe el nombre"
              required
              onChange={inputOnChange}
            ></input>
            <label>Apellidos</label>
            <input
              type="text"
              name="lastname"
              required
              placeholder="Escribe el apellido"
              onChange={inputOnChange}
            ></input>
            <div>
              <label>Teléfono</label>
              <input
                type="text"
                name="phone"
                onChange={inputOnChange}
                required
              ></input>
            </div>
            <div>
              <label>Email</label>
              <input
                type="text"
                name="email"
                onChange={inputOnChange}
                required
              ></input>
            </div>
            <input type={"submit"} className="btn btn-primary"></input>
          </form>
        </div>
      </div>
    </div>
  );
}
