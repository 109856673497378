import { initializeApp } from "firebase/app";
import { firebaseConfig } from ".";
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  deleteUser
} from "firebase/auth";

const app = initializeApp(firebaseConfig);
const auth = getAuth();

function UseAuthFirebase() {
  const createUser = (email, password) => {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          resolve(user.uid);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          reject(errorCode, errorMessage);
        });
    });
  };

  const authLogin = (email, password) => {
    let isLogin = signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        return true;
        // ...
      })
      .catch(() => {
        return false;
      });
    return isLogin;
  };

  const authState = () => {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          resolve(uid);
        } else {
          reject(false);
        }
      });
    });
  };

  const authLogout = () => {
    signOut(auth).then(() => {
      window.location = "";
    });
  };
  return { authLogin, authState, createUser, authLogout };
}

export default UseAuthFirebase;
