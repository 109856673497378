import React from "react";

function PopupInputImages({ handleChange}) {
  return (
    <div>
      <div className="file-container">
        <input
          className="file-container"
          type="file"
          capture="camera"
          onChange={handleChange}
        />
        <input
          className="file-container"
          type="file"
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default PopupInputImages;
