import React, { useState } from "react";
import UseAuthFirebase from "../../Auth/UseAuthFirebase";
import logo from "../../resources/logoP.png";
import "./login.css";

function Login() {
  const { authLogin } = UseAuthFirebase();
  const [dataLogin, setDataLogin] = useState({ email: "", password: "" });
  const [alert, setAlert] = useState(false);

  const handleOnChange = (e) => {
    setDataLogin({ ...dataLogin, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isLogin = await authLogin(dataLogin.email, dataLogin.password);

    if (isLogin) {
      window.location = "";
    } else {
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    }
  };

  return (
    <div id="contenedor">
      <div id="central">
        <div id="login">
          <div className="logo">
            <img src={logo} style={{ width: "100%" }} />
          </div>
          <form id="loginform" onSubmit={handleSubmit}>
            <div id="textInpur">Correo Electronico</div>
            <input
              type="text"
              name="email"
              placeholder="Escribe aquí..."
              onChange={handleOnChange}
              required
            ></input>
            <div id="textInpur">Contraseña</div>
            <input
              type="password"
              name="password"
              placeholder="Escribe aquí..."
              onChange={handleOnChange}
              required
            ></input>
            <div className="pie-form">
              ¿Olvidaste tu Contraseña?
              <a href="#">Clic aquí</a>
            </div>
            <button type="submit" title="Ingesar" name="Imgresar">
              Ingresar
            </button>
          </form>
          {alert && (
            <div className="alert-darger">
              Datos incorrectos, verifica tu correo y contraseña
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
